import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { format } from "date-fns"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ProjectsPage = ({ data }) => {
  const { Title, Slug } = data.strapiPages
  const projects = data.allStrapiProjects.nodes
  return (
    <Layout>
      <SEO title={Title} />
      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-top"
        id={Slug}
      >
        <div className="w-100">
          <h2 className="mb-5 h1">{Title}</h2>
          {projects.map(project => (
            <div
              key={project.id}
              className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5"
            >
              <div className="resume-content">
                <h2 className="mb-0">
                  {project.Title} -{" "}
                  <em className="created-at">
                    Since {format(new Date(project.Created), "LLL, Y")}
                  </em>
                </h2>
                <div className="subheading mb-3">
                  <a title={project.Title} href={project.Link}>
                    {project.Link}
                  </a>
                </div>
                <div>
                  <ReactMarkdown source={project.Description} />
                </div>

                <p className="text-md-left">
                  <span className="text-primary">
                    {project.technologies.map(t => t.Title).join(", ")}
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default ProjectsPage

export const pageQuery = graphql`
  query ProjectsQuery {
    strapiPages(Slug: { eq: "projects" }) {
      Title
      Slug
    }
    allStrapiProjects(sort: { fields: Created, order: DESC }) {
      nodes {
        id
        Title
        Created
        Slug
        Link
        Description
        technologies {
          Title
        }
      }
    }
  }
`
